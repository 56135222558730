import http from '@/libs/http'
export default class LicLogsService {
  paginateLogs (payload) {
    const { limit = 10, offset = 0, filters = {} } = payload
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/logs/paginate?limit=${limit}&offset=${offset}`, {
      params: { filters },
      headers: { loading: true }
    })
  }

  findLog (id) {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/logs/${id}`)
  }
}
