<template>
  <div class="flex justify-between mt-5 mb-3">
    <Button label="Atras"
            icon="pi pi-arrow-left"
            class="mr-2 p-button-danger"
            @click="$router.go(-1)"
    />
  </div>
  <div class="container bg-white rounded-2xl">
    <div class="flex p-3">
        <div class="p-inputgroup mr-3">
          <span class="p-inputgroup-addon">
            <i class="pi pi-list"/>
            <p class="ml-1">Tablas</p>
          </span>
          <Dropdown v-model="tableName"
                    :options="optionstables"
                    optionLabel="name"
                    optionValue="code"
                    placeholder="Seleccione una tabla"
                    :filter="true"
                    filterPlaceholder="Buscar"
          />
        </div>
        <div class="p-inputgroup mr-3">
          <span class="p-inputgroup-addon">
            <i class="pi pi-users"/>
            <p class="ml-1">Usuarios</p>
          </span>
          <Dropdown v-model="userId"
                    :options="optionsUsers"
                    optionLabel="fullName"
                    optionValue="id"
                    placeholder="Seleccione un usuario"
                    :filter="true"
                    filterPlaceholder="Buscar"
          />
        </div>
      <div class="p-inputgroup mr-3">
        <span class="p-inputgroup-addon">
            <i class="pi pi-calendar-times"/>
            <p class="ml-1">Fecha</p>
        </span>
        <Calendar id="range" v-model="rangeFecha" selectionMode="range" :manualInput="false" />
      </div>
        <div class="">
          <Button label="Limpiar" icon="pi pi-trash" class="p-button-danger" iconPos="left" @click="resetFilters" />
        </div>
    </div>
    <div class="p-3">
      <DataTable :value="logs"
                 responsiveLayout="scroll"
                 class="p-datatable-sm"
                 showGridlines

      >
        <Column field="tableName"
                header="Accion"
                headerClass=""
        >
          <template #body="{data}">
            {{ $h.liclogs(data) }}
          </template>
        </Column>
        <Column field="createdAt"
                header="Fecha creacion"
                headerClass=""
        >
          <template #body="{data}">
            <div>
             <span>
               {{$h.formatDate(data.createdAt, 'YYYY-MM-DD')}}
             </span>
            </div>
          </template>
        </Column>
        <Column header="Usuario"
                headerClass=""
        >
          <template #body="{data}">
           <div>
             <span>
               {{data.Usuario.fullName}}
             </span>
           </div>
          </template>
        </Column>
        <Column header="Acciones"
                headerClass=""
        >
          <template #body="data">
            <div class="flex">
              <Button icon="pi pi-eye"
                      class="p-button-rounded p-button-success"
                      v-tooltip.left="'Visualizar logs'"
                      @click="handleShowLogs(data.data)"
              />
            </div>
          </template>
        </Column>
      </DataTable>
      <Paginator v-model:first="offset"
                 :rows="10"
                 :totalRecords="totalRecords"
                 :rowsPerPageOptions="rowsPerPageOptions"
                 @page="onPage($event)"
      />
    </div>
  </div>

  <Dialog v-model:visible="modalLogs" :modal="true" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '80vw'}" header="Logs">
    <table>
      <!-- Cuando es actualizacion -->
      <template v-if="compare.BeforeTextJson !== null">
        <tr>
          <th></th>
          <th>Antes</th>
          <th>Despues</th>
        </tr>
        <tr v-for="(item, index) in compare.BeforeTextJson" :key="index">
<!--          <td>{{index}}</td>-->
          <td>{{ $h.logsColumna(index) }}</td>
          <td>{{item}}</td>
          <td>{{compare.AfterTextJson[index]}}</td>
        </tr>
      </template>
      <!-- Cuando es nuevo registro -->
      <template v-else>
        <tr>
          <th></th>
          <th>Nuevo</th>
        </tr>
        <tr v-for="(item, index) in compare.AfterTextJson" :key="index">
          <td>{{ $h.logsColumna(index) }}</td>
          <td>{{compare.AfterTextJson[index]}}</td>
        </tr>
      </template>
    </table>
    <template #footer>
      <div class="flex justify-end">
        <Button label="Salir" class="p-button-danger" @click="modalLogs = !modalLogs"/>
      </div>
    </template>
  </Dialog>
</template>
<script>
import { computed, onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { helper } from '@/utils/helper'
import LicLogsService from '../../services/LicLogsService'
import LicUsersService from '../../services/LicUsersService'
export default {
  name: 'logs',
  setup () {
    const router = useRouter()
    // const route = useRoute()
    /** data **/
    const totalRecords = ref(0)
    const _LicLogsService = ref(new LicLogsService())
    const _LicUsersService = ref(new LicUsersService())
    const optionstables = ref([
      {
        name: 'Seleccione una opcion',
        code: 'null'
      },
      {
        name: 'Documentos',
        code: 'LicDocumentos'
      },
      {
        name: 'Licitaciones',
        code: 'Licitaciones'
      },
      {
        name: 'CarpetasDocumentos',
        code: 'LicCarpetasDocumentos'
      },
      {
        name: 'Pliegos',
        code: 'LicPliegos'
      },
      {
        name: 'Carpetas',
        code: 'LicCarpetas'
      }
    ])
    const optionsUsers = ref([])
    const tableName = ref(null)
    const userId = ref(null)
    const rangeFecha = ref(null)
    const logs = ref([])
    const offset = ref(0)
    const limit = ref(10)
    /** computed **/
    const filters = computed(() => {
      return {
        createdAt: rangeFecha.value,
        tableName: tableName.value,
        createdBy: userId.value
      }
    })
    const rowsPerPageOptions = computed(() => {
      return [5, 10, 30, 50, totalRecords.value]
    })
    const compare = ref(null)
    const modalLogs = ref(false)
    /** whatch **/
    watch(filters, (a) => {
      router.query = {
        offset: offset.value,
        limit: limit.value,
        filters: a
      }
      getLogs()
    })
    /** methods **/
    const getLogs = () => {
      const payload = {
        limit: limit.value,
        offset: offset.value,
        filters: filters.value
      }
      _LicLogsService.value.paginateLogs(payload).then(({ data }) => {
        console.log(data)
        logs.value = data.rows
        totalRecords.value = data.count
      }).catch(err => console.error(err))
    }

    const handleShowLogs = async ({ id }) => {
      _LicLogsService.value.findLog(id).then(({ data }) => {
        compare.value = data
        modalLogs.value = !modalLogs.value
      })
    }

    const resetFilters = () => {
      rangeFecha.value = null
      tableName.value = null
      userId.value = null
    }

    const setQuery = () => {
      return new Promise((resolve) => {
        if (!router.query) resolve(true)
        if (router.query.filters) {
          const { createdAt, tableName: tabla, createdBy } = router.query.filters
          rangeFecha.value = createdAt ?? null
          tableName.value = tabla ?? null
          userId.value = createdBy ?? null
        }
        const { limit, offset } = router.query
        limit.value = limit ?? 10
        offset.value = offset ?? 0
        resolve(true)
      })
    }
    const onPage = (event) => {
      console.log(event)
      limit.value = event.rows
      getLogs()
    }
    const getUsuarios = () => {
      _LicUsersService.value.getUsers().then(({ data }) => {
        optionsUsers.value.push({
          id: null,
          fullName: 'Seleccione una opcion'
        })
        optionsUsers.value.push(...data)
      })
    }
    /** hooks **/
    onMounted(async () => {
      await setQuery()
      await getUsuarios()
      getLogs()
    })
    return {
      optionstables,
      tableName,
      optionsUsers,
      userId,
      rangeFecha,
      logs,
      helper,
      handleShowLogs,
      totalRecords,
      rowsPerPageOptions,
      offset,
      onPage,
      compare,
      modalLogs,
      resetFilters
    }
  }
}
</script>
<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
</style>
